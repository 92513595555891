// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "html {\n  background-color: #cffafa;\n  color: #173F3F;\n  font-family: \"Slabo 27px\", serif;\n  width: 100%;\n  height: 100vh;\n}\n\n* a {\n  color: #C75314\n}\n\n* a:hover {\n  color: #80360d\n}\n\n._3l6tUHbCLjVyxilYRqe0PJ {\n  padding: 16px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n._1ZYJdSNx6jqtlSQ81JbSnn {\n  max-width: 1100px;\n}\n\n@media (min-width: 960px) {\n\n  ._2jkwz_kZZWtRFbpY3oROnA {\n    margin-left: 38px\n  }\n}", "",{"version":3,"sources":["App.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;EACd,gCAAgC;EAChC,WAAW;EACX,aAAa;AACf;;AAEA;EACE;AACF;;AAEA;EACE;AACF;;AAEA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;;EAEE;IACE;EACF;AACF","file":"App.css","sourcesContent":["html {\n  background-color: #cffafa;\n  color: #173F3F;\n  font-family: \"Slabo 27px\", serif;\n  width: 100%;\n  height: 100vh;\n}\n\n* a {\n  color: #C75314\n}\n\n* a:hover {\n  color: #80360d\n}\n\n.app {\n  padding: 16px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.inner {\n  max-width: 1100px;\n}\n\n@media (min-width: 960px) {\n\n  .bottomBox {\n    margin-left: 38px\n  }\n}"]}]);
// Exports
exports.locals = {
	"app": "_3l6tUHbCLjVyxilYRqe0PJ",
	"inner": "_1ZYJdSNx6jqtlSQ81JbSnn",
	"bottomBox": "_2jkwz_kZZWtRFbpY3oROnA"
};
module.exports = exports;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._2IlME95RIxhuRHiBS95n7J {  margin-bottom: 16px;\n}\n\n._2bGpMtKG2Hp5iTuAVVUg_T {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  text-align: center;\n  width: 325px;\n}\n\n._29iyqbUEuzpesqQPIEKSt_ {\n  font-size: 30px;\n}\n\n.RYkEeValUstM9GJ0ib63i {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-end;\n}\n\n._2qdRg1PlrMAADHchASIxQ3 {\n  font-size: 17px;\n  margin: 0;\n  margin-right: 8px;\n}\n\n@media (min-width: 960px) {\n  ._2qdRg1PlrMAADHchASIxQ3, ._179pxRDaNIizq46ySRNoJk {\n    font-size: 22px;\n  }\n}\n", "",{"version":3,"sources":["Project.css"],"names":[],"mappings":"AAAA,4BAAY,mBAAmB;AAC/B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,uBAAuB;EACvB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,SAAS;EACT,iBAAiB;AACnB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","file":"Project.css","sourcesContent":[".section {  margin-bottom: 16px;\n}\n\n.project {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  text-align: center;\n  width: 325px;\n}\n\n.heading {\n  font-size: 30px;\n}\n\n.textAndLink {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-end;\n}\n\n.header {\n  font-size: 17px;\n  margin: 0;\n  margin-right: 8px;\n}\n\n@media (min-width: 960px) {\n  .header, .projectLink {\n    font-size: 22px;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"section": "_2IlME95RIxhuRHiBS95n7J",
	"project": "_2bGpMtKG2Hp5iTuAVVUg_T",
	"heading": "_29iyqbUEuzpesqQPIEKSt_",
	"textAndLink": "RYkEeValUstM9GJ0ib63i",
	"header": "_2qdRg1PlrMAADHchASIxQ3",
	"projectLink": "_179pxRDaNIizq46ySRNoJk"
};
module.exports = exports;

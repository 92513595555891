// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".dZv72ptCOplmvk0eppigh {\n  font-size: 17px;\n\n  list-style-type: none;\n}\n\n._5uy5khlg0YejpeqM089TE {\n  margin-top: 8px;\n  margin-bottom: 8px;\n  margin-right: 4px\n}\n\n._3j_yCGcKpVgUopfdS2TG0X {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n}\n\n._3k4DRIMOGcd3RwsdA7MucG {\n  font-size: 30px;\n}\n\n.E3CdYNE0nwSwv2Dbd_Ezl {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  padding-left: 0;\n  margin-top: 0;\n}\n\n@media (min-width: 960px) {\n  .dZv72ptCOplmvk0eppigh {\n    font-size: 22px;\n  }\n}", "",{"version":3,"sources":["Tech.css"],"names":[],"mappings":"AAAA;EACE,eAAe;;EAEf,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB;AACF;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,uBAAuB;AACzB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,eAAe;EACf,aAAa;AACf;;AAEA;EACE;IACE,eAAe;EACjB;AACF","file":"Tech.css","sourcesContent":[".techLine {\n  font-size: 17px;\n\n  list-style-type: none;\n}\n\n.techP {\n  margin-top: 8px;\n  margin-bottom: 8px;\n  margin-right: 4px\n}\n\n.tech {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n}\n\n.heading {\n  font-size: 30px;\n}\n\n.techNames {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  padding-left: 0;\n  margin-top: 0;\n}\n\n@media (min-width: 960px) {\n  .techLine {\n    font-size: 22px;\n  }\n}"]}]);
// Exports
exports.locals = {
	"techLine": "dZv72ptCOplmvk0eppigh",
	"techP": "_5uy5khlg0YejpeqM089TE",
	"tech": "_3j_yCGcKpVgUopfdS2TG0X",
	"heading": "_3k4DRIMOGcd3RwsdA7MucG",
	"techNames": "E3CdYNE0nwSwv2Dbd_Ezl"
};
module.exports = exports;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._4tYfmS9AVx3ogyv9uuf9D {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n}\n\n._3l-MlpjzIcacTTPQfvh_8H {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n}\n\n._19OY3PXZ0N7Ma9lxVgvBPx {\n  margin-bottom: 20px;\n  font-size: 30px;\n  margin-left: 5px;\n}\n\n.EyjJSWMbxpVIlm9jEzzyN {\n  margin-left: 5px;\n  margin-right: 3px;\n  margin-bottom: 5px;\n  font-family: \"Slabo 27px\", serif;\n  font-size: 17px;\n}\n\n.EfLvlZFeqhK6o7qnS-ZCi:hover {\n  color: rosybrown\n}\n\n@media (min-width: 960px) {\n  .EyjJSWMbxpVIlm9jEzzyN {\n    font-size: 22px;\n  }\n}", "",{"version":3,"sources":["Links.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,gCAAgC;EAChC,eAAe;AACjB;;AAEA;EACE;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF","file":"Links.css","sourcesContent":[".links {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n}\n\n.linkList {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n}\n\n.findMe {\n  margin-bottom: 20px;\n  font-size: 30px;\n  margin-left: 5px;\n}\n\n.contactLinks {\n  margin-left: 5px;\n  margin-right: 3px;\n  margin-bottom: 5px;\n  font-family: \"Slabo 27px\", serif;\n  font-size: 17px;\n}\n\n.constactLinks:hover {\n  color: rosybrown\n}\n\n@media (min-width: 960px) {\n  .contactLinks {\n    font-size: 22px;\n  }\n}"]}]);
// Exports
exports.locals = {
	"links": "_4tYfmS9AVx3ogyv9uuf9D",
	"linkList": "_3l-MlpjzIcacTTPQfvh_8H",
	"findMe": "_19OY3PXZ0N7Ma9lxVgvBPx",
	"contactLinks": "EyjJSWMbxpVIlm9jEzzyN",
	"constactLinks": "EfLvlZFeqhK6o7qnS-ZCi"
};
module.exports = exports;
